import React, { useState } from "react";
import { Grid, CardContent, Card } from "@material-ui/core";
import {
  ProjectsContainer,
  InfoWrapper,
  Subtitle,
  Title,
  Img,
  Heading,
  Code,
  View,
  BtnWrapper,
  Tools,
} from "./ProjectElements";
import { ProjectsInfo } from "./Data";
import { Icon } from "../Icon";
import ProjectIcon from "../../assets/projects.svg";
import { Button } from "../ButtonElement";

function Projects() {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <ProjectsContainer>
      <div style={{height: '0.1rem'}} id="projects"></div>
      <Heading>
        <Icon src={ProjectIcon} alt="icon" /> Projects so far{" "}
      </Heading>
      <Grid container spacing={5}>
        {ProjectsInfo.map(
          ({
            id,
            ss,
            projectName,
            description,
            sourceLink,
            liveLink,
            toolsUsed,
          }) => (
            <Grid item xs={12} sm={12} md={6} key={id}>
              <Card>
                <CardContent>
                  <Img
                    src={ss.default}
                    alt={id}
                  />
                  
                  <InfoWrapper>
                    <Title>{projectName}</Title>
                    {toolsUsed.map((tool) => (
                      <Tools key={tool}>{tool}</Tools>
                    ))}
                    <Subtitle>{description}</Subtitle>
                  </InfoWrapper>
                </CardContent>
                <BtnWrapper>
                {sourceLink ? 
                  <Button
                    href={sourceLink}
                    target="_blank"
                    onMouseEnter={onHover}
                    onMouseLeave={onHover}
                  >
                    Source Code <Code />
                  </Button>: null}
                  
                  {liveLink ?
                    <Button
                      target="_blank"
                      href={liveLink}
                      onMouseEnter={onHover}
                      onMouseLeave={onHover}
                    >
                      View Live <View />
                    </Button>
                  : null}
                </BtnWrapper>
              </Card>
            </Grid>
          )
        )}
      </Grid>
    </ProjectsContainer>
  );
}

export default Projects;
