import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';


class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 29.033670,
      lng: 80.131605,
    },
    zoom: 11
  };

  render() {
    return (
      <div style={{ height: '55vh', width: '95%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBwcuZ9rJ6-sefoB1OZ2G63XVeCHzKJFxg' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          yesIWantToUseGoogleMapApiInternals
        >
        <Marker
          lat={29.033670}
          lng={80.131605}
        />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;

const Marker = ({ show, place }) => {
  const markerStyle = {
    border: '1px solid white',
    borderRadius: '50%',
    height: 15,
    width: 15,
    backgroundColor: show ? 'red' : 'blue',
    cursor: 'pointer',
    zIndex: 10,
  };

  return (
    <>
      <div style={markerStyle}>
        <InfoWindow  />
      </div>
    </>
  );
};

const InfoWindow = () => {
  const infoWindowStyle = {
    position: 'relative',
    bottom: 32,
    left: '-40px',
    width: 90,
    backgroundColor: 'white',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: '5px',
    fontSize: '3px',
    zIndex: 100,
  };

  return (
    <div style={infoWindowStyle}>
      <div style={{ fontSize: 13 }}>
        Ringona-09
      </div>
    </div>
  );
};