import React from "react";
import SocialIconsBar from "../SocialIcons";
import {
  CloseIcon,
  Icon,
  SidebarContainer,
  SidebarWrapper,
  SidebarLink,
  SidebarMenu,
  SidebarRoute,
  SideBtnWrap,
} from "./SidebarElements";

function Sidebar({ isOpen, toggle }) {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu >
          <SidebarLink
            onClick={toggle}
            to="about"
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            About
          </SidebarLink>
          <SidebarLink
            onClick={toggle}
            to="projects"
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Projects
          </SidebarLink>
          <SidebarLink
            onClick={toggle}
            to="contact"
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Contact
          </SidebarLink>
          <SocialIconsBar primary/>
        </SidebarMenu>
        <SideBtnWrap>
          <SidebarRoute href="mailto:tekrajjoshi07@gmail.com">
            Let's Chat
          </SidebarRoute>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
}

export default Sidebar;
