import styled from 'styled-components'

export const TimelineContainer = styled.div`
    height: 860px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
`

export const TimelineWrapper = styled.div`
    z-index: 1;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 100px 0;
    justify-content: center;
`

export const Name = styled.h6 `
    font-size: 18px;
    color: #212221;

    @media screen and (max-width: 768px) {
        font-size: 15px;
    }
    @media screen and (max-width: 780px) {
        font-size: 13.5px;
    }
`

export const Edu = styled.p `
    font-size: 16px;
    margin-top: 3px;
    margin-bottom: 8px;
    color: #212221;

    @media screen and (max-width: 700px) {
        font-size: 14px;
    }

    @media screen and (max-width: 480px) {
        font-size: 12.5px;
    }
`

export const Timestamp = styled.p `
    font-size: 13px;
    margin-bottom: 13px;
    color: #212221;

    @media screen and (max-width: 700px) {
        font-size: 11px;
    }
`

export const Logo = styled.img `
    height: 65px;
    width: 65px;
    margin-top: -23px;
    border-radius: 50%;
    margin-bottom: 7px;

    
    @media screen and (max-width: 768px) {
        height: 60px;
        width: 60px;
        margin-top: -20px;
        margin-bottom: 6px;
    }

    @media screen and (max-width: 480px) {
        height: 50px;
        width: 50px;
        margin-top: -18px;
        margin-bottom: 1px;
    }
`

export const Heading = styled.h6`
    margin-bottom: 50px;
    color: #44449B;
    font-size: 35px;
    text-align: center;
    @media screen and (max-width: 768px) {
        font-size: 25px;
    }
    @media screen and (max-width: 480px) {
        font-size: 23px;
    }
`