import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import {
  Form,
  Input,
  TextArea,
  Button,
  Heading,
  ContactContainer,
  Loc,
  Address,
  AddressWrapper,
  GithubAction,
  Msg,
  ScrollGif,
  ScrollToTop
} from "./ContactElements";
import SimpleMap from "./Map";
import ScrollUp from "../../assets/scroll.gif";
import Gif from "../../assets/contact.gif";

function Contact() {
  const [status, setStatus] = useState("");

  const submitForm = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setStatus("SUCCESS");
      } else {
        setStatus("ERROR");
      }
    };
    xhr.send(data);
  };

  return (
    <ContactContainer >
      <GithubAction href="https://github.com/T3KRAJ?tab=repositories">
        Checkout more projects in github.
      </GithubAction>
      <div style={{height: '1rem'}}></div>
      <Grid container spacing={1} id="contact">
        <Grid item xs={12} md={6}>
          <Heading>
            Let's Connect
            <Msg src={Gif} alt="gif" />
          </Heading>
          <Form
            onSubmit={submitForm}
            action="https://formspree.io/f/meqpknay"
            method="POST"
          >
            <Input name="name" type="text" placeholder="Your Name" />
            <Input name="email" type="email" placeholder="Email" required />
            <TextArea
              name="message"
              type="text"
              placeholder="Hi there!"
              required
            />
            {status === "SUCCESS" ? (
              <p>Thanks!</p>
            ) : (
              <Button type="submit" onSubmit={submitForm}>
                Send Message
              </Button>
            )}
            {status === "ERROR" && <p>Ooops! There was an error.</p>}
          </Form>
        </Grid>
        <Grid item xs={12} md={6}>
          <AddressWrapper>
            <Address>Address</Address>
            <Loc>248011, Selaqui, Dehradun, India | 10400, Mahendranagar, Kanchanpur, Nepal</Loc>
          </AddressWrapper>

          <SimpleMap />
        </Grid>
      </Grid>
      <ScrollToTop
        to="top"
        activeClass="active"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
      >
        <ScrollGif src={ScrollUp} alt="gif" />
      </ScrollToTop>
    </ContactContainer>
  );
}
export default Contact;
