export const EduInfo = [{
    id: 'shivalik',
    logo: require('../../assets/shivalik.jpg'),
    name: 'Shivalik college of engineering',
    edu: "Bachelor's Degree in Computer Science & Engineering",
    timestamp: '(2018 - current)',
},
{
    id: 'radiant',
    logo: require('../../assets/radiant.jpg'),
    name: 'Radiant higher secondary school',
    edu: 'Intermediate of Science (I. Sc.)',
    timestamp: '(2016 - 2018)',
},
{
    id: 'diamond',
    logo: require('../../assets/diamond.jpg'),
    name: 'Diamond public academy',
    edu: 'High School (SLC Board)',
    timestamp: '(2014 - 2016)',
},
{
    id: 'batika',
    logo: require('../../assets/batika.jpg'),
    name: 'Batika international public school',
    edu: 'Elementary & lower secondary school',
    timestamp: '(2004 - 2014)',
},
]