export const SkillInfo = [
    {
        id : 'web',
        logo: require('../../assets/web.svg'),
        title: 'Web design and development',
        skills: 'HTML/CSS/JS/Typescript',
    },
    {
        id : 'python',
        logo: require('../../assets/python.svg'),
        title: 'Python Scripting',
        skills: 'Python 3/BeautifulSoup',
    },
    {
        id : 'database',
        logo: require('../../assets/postgresql.svg'),
        title: 'Database Management System',
        skills: 'Postgresql/Sqlite/MongoDB',
    },

    {
        id : 'frontend',
        logo: require('../../assets/react.svg'),
        title: 'Frontend Frameworks',
        skills: 'React.js/Angular.js',
    },
    {
        id : 'backend',
        logo: require('../../assets/django.svg'),
        title: 'Backend Frameworks',
        skills: 'Django/Flask',
    },
    {
        id : 'frontend-tools',
        logo: require('../../assets/tailwind.svg'),
        title: ' Frontend Tools',
        skills: 'Material UI/Bootstrap/Tailwind',
    },
    {
        id : 'ui/ux',
        logo: require('../../assets/figma.svg'),
        title: 'UI/UX Design',
        skills: 'Figma',
    },

    {
        id : 'photography',
        logo: require('../../assets/lightroom.svg'),
        title: 'Photography',
        skills: 'Lightroom/Adobe Photoshop',
    },
    {
        id : 'others',
        logo: require('../../assets/git.svg'),
        title: 'Others',
        skills: 'Git/Firebase',
    },
]