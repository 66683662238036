import styled, {keyframes} from 'styled-components'
import skillsbg from '../../assets/skillsbg.svg';
import { bounce } from 'react-animations';
const bounceAnimation = keyframes`${bounce}`;


export const SkillsContainer = styled.div `
    height: 520px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 40px 60px;
    justify-content: center;
    background-image: url(${skillsbg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media screen and (max-width: 1000px) {
        height: auto;
        padding: 60px 20px;
    }
`

export const Img = styled.img `
    height: 40px;
    width: 40px;
    float: left;
    padding-right: 10px;
    
    @media screen and (max-width: 768px) {
        height: 37px;
        width: 47px;
    }
    @media screen and (max-width: 480px) {
        height: 34px;
        width: 44px;
        margin-right: 3px;
    }
`

export const SkillWrapper = styled.div `
    animation: 2s ${bounceAnimation};
    height: auto;
    width: auto;
    border: 0.5px solid #212221;
    padding: 10px;
    color: #DDEDF4;
    overflow:auto;
`

export const Title = styled.h6 `
    font-size: 14px;

    @media screen and (max-width: 768px) {
        font-size: 12px;
    }
    @media screen and (max-width: 480px) {
        font-size: 11px;
    }
`

export const SkillData = styled.p `
    font-size: 15px;

    @media screen and (max-width: 768px) {
        font-size: 12px;
    }
    @media screen and (max-width: 480px) {
        font-size: 11px;
    }
`

export const Heading = styled.h6`
    margin: 50px 0;
    color: #DDEDF4;
    font-size: 35px;
    text-align: center;
    @media screen and (max-width: 768px) {
        font-size: 25px;
    }
`