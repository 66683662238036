import styled from 'styled-components'
import {Link as LinkScroll} from 'react-scroll' 


export const ContactContainer = styled.div `
    flex-grow: 1;
    margin: 55px 40px;

    @media screen and (max-width: 768px) {
        margin: 10px 40px;
    }
`


export const Form = styled.form `
    max-width:420px; 
    margin:  40px 50px;

    @media screen and (max-width: 768px) {
        margin: 0 3px;
    }
`

export const Input = styled.input `
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 18px;
    border-radius: 5px;
    line-height: 22px;
    background-color: transparent;
    border: 2px solid #44449B;
    transition: all 0.3s;
    padding: 13px;
    margin-bottom: 15px;
    width:100%;
    box-sizing: border-box;
    outline:0;

    &:focus {
        border: 2px solid #212221;
    }
`

export const TextArea = styled.textarea `
    height: 150px;
    line-height: 150%;
    resize:vertical;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 18px;
    border-radius: 2px;
    line-height: 22px;
    background-color: transparent;
    border: 2px solid #44449B;
    transition: all 0.3s;
    padding: 13px;
    margin-bottom: 15px;
    width:100%;
    box-sizing: border-box;
    outline:0;

    &:focus {
        border: 2px solid #212221;
    }
`

export const Button = styled.button `
    border-radius: 0px;
    background: #1181B2;
    color: #DDEDF4;
    white-space: nowrap;
    padding: 10px 35px;
    outline: none;
    border: none;
    text-decoration: none;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        transitions: all 0.2s ease-in-out;
        background: #212221;
        color: #DDEDF4;
    }
`

export const Heading = styled.h6`
    margin: 40px 50px;
    color: #212221;
    font-size: 35px;
    text-align: left;

    @media screen and (max-width: 768px) {
        font-size: 27px;
        margin-left: 0;
    }

`
export const Address = styled.h6 `
    font-size: 22px;
    margin-bottom: 4px;
    font-weight: bold;
    text-align: left;
    color: #212221;
`

export const Loc = styled.p `
    font-size: 18px;
    text-align: left;
    color: #212221;
`

export const AddressWrapper = styled.div `
    width: 200px;
    margin: 20px 2px;

`

export const GithubAction = styled.a `
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    margin: 0 50px;
    padding: 2px;
    font-weight: bold;
    color: #44449B;
    cursor: pointer;

    &:hover {
        border: 1px solid #44449B; 
        font-size: 17.9px;
        transition: all 0.2s ease-in-out;
    }
    @media screen and (max-width: 768px) {
        font-size: 17px;
        margin: 30px 3px;
    }
`

export const Msg = styled.img `
    height: 140px;
    width: 140px;
    display: inline;
    margin-bottom: -40px;

    @media screen and (max-width: 768px) {
        height: 120px;
        width: 120px;
    }
`

export const ScrollGif = styled.img `
    height: 100px;
    width: 100px;
    color: red;
    transform: rotate(180deg);
    float: right;
    right: 0;

    @media screen and (max-width: 768px) {
        height: 80px;
        width: 80px;
    }
    @media screen and (max-width: 768px) {
        height: 60px;
        width: 60px;
        margin-top: 70px;
    }
`

export const ScrollToTop = styled(LinkScroll)`
    cursor: pointer;
`;