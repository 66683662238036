import React from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { Heading, TimelineContainer, Name, Edu, Timestamp, Logo, TimelineWrapper } from './EducationElements';
import {EduInfo} from './Data'
import { Icon } from '../Icon';
import EducationIcon from '../../assets/education.svg'

export default function ColorsTimeline() {
  return (
      <TimelineContainer>
        <TimelineWrapper>
            <Heading><Icon src={EducationIcon} alt="icon" /> Education</Heading>
            <Timeline align="alternate">
            {EduInfo.map(({id, name, edu, timestamp, logo}) => 
                <TimelineItem key={id}>
                    <TimelineSeparator>
                        <TimelineDot color="primary"/>
                        {id !== 'batika' ? <TimelineConnector /> : <> </>}
                    </TimelineSeparator>
                    <TimelineContent>
                        <Logo src={logo.default} alt={id}/>
                        <Name>{name}</Name>
                        <Edu>{edu}</Edu>
                        <Timestamp>{timestamp}</Timestamp>
                    </TimelineContent>
                </TimelineItem>
            )}
            </Timeline>
        </TimelineWrapper>
    </TimelineContainer>
    
  );
}
