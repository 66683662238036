import React, { useState } from 'react'
import About from '../components/About'
import Contact from '../components/Contact'
import Education from '../components/Education'
import Footer from '../components/Footer'
import Hero from '../components/HeroSection'
import Navbar from '../components/Navbar'
import Projects from '../components/Projects'
import Sidebar from '../components/Sidebar'
import Skills from '../components/Skills'

function Home() {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Navbar toggle={toggle}/>
            <Hero />
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <About />
            <Education />
            <Skills />
            <Projects />
            <Contact />
            <Footer />
        </>
    )
}

export default Home
