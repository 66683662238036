import styled, {keyframes} from 'styled-components'
import { Link } from "react-scroll";
import { bounceInRight, bounceInLeft } from 'react-animations';
const bounceRightAnimation = keyframes`${bounceInRight}`;
const bounceLeftAnimation = keyframes`${bounceInLeft}`;

export const HeroContainer = styled.div`
    height: 100vh;

    :before {
        content: '';
        position: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0.2) 0% ,rgba(0,0,0,0.6) 100%), linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
        z-index: 2;
    }
`
export const HeroWrapper = styled.div`
    left: 0;
    right: 0;
    display: grid;
    z-index: 999;
    height: 100vh;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 2rem;
    padding: 0 24px;
    justify-content: center;
`
export const HeroContent = styled.div`
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
`

export const Row = styled.div`
    display: grid;
    margin-top: 5.9rem;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: 'col1 col2';
    @media screen and (max-width: 760px) {
        grid-template-areas: 'col1 col1' 'col2 col2';
        margin-top: 7.7rem;

    }
`

export const Column1 = styled.div`
    grid-area: col1;
`

export const Column2 = styled.div`
    grid-area: col2;
`

export const ImgWrap =styled.div`
    max-width: 800px;
`

export const Img =styled.img`
    width: 560px;
    height: 560px;
    padding-right: auto;
    animation: 1s ${bounceRightAnimation};
    @media screen and (max-width: 760px) {
        display: none;
    }
`

export const HeroH4 = styled.p`
    color: #44449B;
    font-size: 24px;
    text-align: left;
    max-width: 500px;

    @media screen and (max-width: 768px) {
        font-size: 19px;
    }
`
export const HeroH1 = styled.h1`
    color: #212221;
    font-size: 67px;
    text-align: left;
    font-family: 'Black Ops One', cursive;
    letter-spacing: 1.4px;

    @media screen and (max-width: 1090px) {
        font-size: 60px;
    }
    @media screen and (max-width: 995px) {
        font-size: 55px;
    }
    @media screen and (max-width: 920px) {
        font-size: 42px;
    }
    @media screen and (max-width: 400px) {
        font-size: 37px;
    }
`
export const HeroP = styled.p`
    margin-bottom: 20px;
    color: #44449B;
    font-size: 24px;
    text-align: left;
    max-width: 500px;
    
    @media screen and (max-width: 768px) {
        font-size: 21px;
    }
`

export const HeroBtnWrapper = styled.div`
    margin-top: 30px;
    display: flex;
    width: 215px;
`

export const Gif = styled.img `
    height: 100px;
    width: 100px;
    display: inline;
    margin-bottom: -20px;
    margin-left: -20px;
    animation: 2s ${bounceLeftAnimation};

`

export const ScrollDown = styled(Link) `
    display: flex;
    justify-content: center;
    cursor: pointer;
`
export const ScrollGif = styled.img `
    height: 110px;
    width: 110px;

    @media screen and (max-width: 768px) {
        height: 80px;
        width: 80px;
    }
`