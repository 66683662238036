import styled from 'styled-components'
// import {Link} from 'react-scroll'

export const Button = styled.a`
    border-radius: 0px;
    background: #1181B2;
    color: #DDEDF4;
    white-space: nowrap;
    padding: 10px 35px;
    outline: none;
    border: none;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;
    &:hover {
        transitions: all 0.2s ease-in-out;
        background: #212221;
        color: #DDEDF4;
    }

    @media screen and (max-width: 760px) {
        padding:  ${props => props.large ? "5px 8px" : "3px 4px"};
        font-size: ${props => props.large ? "18px" : "13px"};
    }
`