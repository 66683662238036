export const ProjectsInfo = [
    {
        id: 'tap',
        projectName: "Talent Assessment Portal",
        description: "A full-stack web app that helps students to prepare for different assessments.",
        ss: require("../../assets/tap.jpg"),
        toolsUsed: ['Flask', 'ReactJS', "MongoDB", 'Material-ui'],
        sourceLink: null,
        liveLink: "https://tap.shivalikcollege.edu.in/",
    },
    {
        id: 'scat',
        projectName: "Entrance Examination Portal",
        description: "Entrance examination portal for Shivalik College of Engineering with numerous security features.",
        ss: require("../../assets/scat.jpg"),
        toolsUsed: ['Django', 'ReactJS', "PostgreSQL", 'Tailwind css'],
        sourceLink: null,
        liveLink: "https://scat.shivalikcollege.edu.in/login",
    },
    {
        id: 'spotify',
        projectName: "Spotify Clone",
        description: "A demo replica of Spotify UI (using spotify web api) that almost works as spotify itself.",
        ss: require("../../assets/spotify.png"),
        toolsUsed: ['ReactJS', 'BEM', 'Material-ui', "Spotify's API"],
        sourceLink: "https://github.com/T3KRAJ/spotify-clone",
        liveLink: null,
    },
    {
        id: 'corona',
        projectName: "Corona Tracker",
        description: "Dashboard for tracking Coronavirus (COVID-19) across the globe utilizing 'https://covid19.mathdro.id/api'.",
        ss: require("../../assets/corona.png"),
        toolsUsed: ['ReactJS', 'Material-ui'],
        sourceLink: "https://github.com/T3KRAJ/COVID-19-tracker-app",
        liveLink: "https://track-covidcases.netlify.app/",
    },
    {
        id: 'On the spot',
        projectName: "On the spot",
        description: "A simple news aggregator web app utilizing 'https://kathmandupost.com' by web scrapping using python.",
        ss: require("../../assets/onthespot.png"),
        toolsUsed: ['Django', "BeautifulSoup"],
        sourceLink: "https://github.com/T3KRAJ/On-the-spot",
        liveLink: null,
    },
    {
        id: 'weather',
        projectName: "Weather App",
        description: "A  Web App that utilizes the 'OpenWeatherMap' API to return current and 7 days forecasted weather for a given location.",
        ss: require("../../assets/weather.png"),
        toolsUsed: ['ReactJS', 'Material-ui'],
        sourceLink: "https://github.com/T3KRAJ/React-Weather-App",
        liveLink: "https://monitor-weather.netlify.app/",
    }
] 