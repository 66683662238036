import styled from 'styled-components'
import {AiFillHeart} from 'react-icons/ai'

export const Love = styled(AiFillHeart) `
    color: #921;
`

export const FooterContainer = styled.div `
    height: 120px;
    width: 100%;
    text-align: center;
    display: block;
    background-color: #44449B;
    color: #DDEDF4;
    bottom: 0;
    margin-top: 40px;
`

export const IconWrapper = styled.div `
    padding-top: 15px;
`
export const FinalLine = styled.h6 `
    font-size: 20px;
    align-self: left;
    margin-left: 40px;
    margin-top: 32px;
`
export const FooterLine = styled.h6 `
    font-size: 20px;
    align-self: center;
    margin: 5px 0;

    @media screen and (max-width: 600px) {
        font-size: 16px;
    }
`
