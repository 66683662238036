import styled from 'styled-components'
import { animated } from 'react-spring'
import Profile from '../../assets/profile.png'


export const AboutContainer = styled.div`
    background: #212221;
    height: 860px;
    :before {
        content: '';
        position: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0.2) 0% ,rgba(0,0,0,0.6) 100%), linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
        z-index: 2;
    }
`
export const AboutWrapper = styled.div`
    display: grid;
    z-index: 1;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 100px 0;
    justify-content: center;
    @media screen and (max-width: 768px) {
        padding: 65px 0;
    }
`
export const AboutContent = styled.div`
    max-width: 600px;
    padding-top: 0;
    padding-bottom: 60px;
`

export const Row = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: 'col1 col2';
    @media screen and (max-width: 760px) {
        grid-template-areas: 'col1 col1' 'col2 col2';
    }
`

export const Column1 = styled.div`
    padding: 0 15px;
    grid-area: col1;
`

export const Column2 = styled.div`
    padding: 0 15px;
    grid-area: col2;
`

export const ProfileWrap = styled.div`
    max-width: 400px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Heading = styled.h6`
    margin-bottom: 50px;
    color: #44449B;
    font-size: 35px;
    text-align: left;
    @media screen and (max-width: 768px) {
        font-size: 25px;
    }
`

export const Subtitle = styled.p`
    color: #DDEDF4;
    font-size: 20px;
    text-align: left;
    max-width: 500px;
    @media screen and (max-width: 768px) {
        font-size: 17px;
    }
    @media screen and (max-width: 480px) {
        font-size: 14px;
    }
`

export const AboutBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    width: 220px;
`

export const ProfileCard = styled(animated.div) `
    min-width: 40ch;
    min-height: 40ch;
    max-width: 100ch;
    max-height: 100ch;
    background-image: url(${Profile});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 10px 0;
    border-radius: 50%;
    padding-right: 0;
    @media screen and (max-width: 760px) {
        min-width: 25ch;
        min-height: 25ch;
        max-width: 70ch;
        max-height: 70ch;
        padding: auto;
        margin: 20px 0;
        align-items: center;
    }
`