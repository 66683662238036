import styled from "styled-components";
import { FaCode } from "react-icons/fa";
import { BiShowAlt } from "react-icons/bi";

export const ProjectsContainer = styled.div`
  flex-grow: 1;
  padding: 20px 70px;
  background: #fff;
  background: linear-gradient(
    #fff,
    #ddedf4
  ); 

  @media screen and (max-width: 768px) {
    padding: 10px 20px;
  }
`;

export const Title = styled.h6`
  font-size: 22px;
  font-weight: bold;
  margin: 20px 5px;
  color: #212221;
`;
export const Subtitle = styled.p`
  font-size: 18px;
  margin: 10px 5px;
  color: #212221;
`;
export const Img = styled.img`
  height: 100%;
  width: 100%;
  filter: brightness(100%);
  transition: 0.4s cubic-bazier(o.o75, 0.83, 0.165, 1);

  &:hover {
    filter: brightness(50%);
    cursor: pointer;
  }
`;

export const InfoWrapper = styled.div`
  width: 100%;
  margin: 10px 5px;
`;
export const Heading = styled.h6`
  margin: 50px 0;
  color: #44449b;
  font-size: 35px;
  text-align: center;
  @media screen and (max-width: 768px) {
    font-size: 25px;
  }
`;

export const Code = styled(FaCode)`
  margin-left: 7px;
  font-size: 26px;
  color: #ddedf4;
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;
export const Tools = styled.div`
  font-size: 14px;
  padding: 1px 2px;
  font-weight: bold;
  border: 3px solid #44449b;
  display: inline;
  width: auto;
  margin: 0 3px;
  justify-content: center;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
`;

export const View = styled(BiShowAlt)`
  margin-left: 7px;
  font-size: 26px;
  color: #ddedf4;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;
export const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 12px 24px;
`;
