import styled from 'styled-components'


export const Icon = styled.img `
    height: 50px;
    width: 50px;
    margin-bottom: -7.5px;

    @media screen and (max-width: 768px) {
        height: 40px;
        width: 40px;
    }
`