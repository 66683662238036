import React, { useState } from "react";
import {
  HeroContainer,
  HeroWrapper,
  HeroContent,
  HeroH4,
  HeroP,
  HeroH1,
  HeroBtnWrapper,
  Row,
  Column1,
  Column2,
  Img,
  ImgWrap,
  Gif,
  ScrollDown,
  ScrollGif,
} from "./HeroElements";

import Resume from "../../assets/resume.pdf";
import Svg from "../../assets/prog.svg";
import { Button } from "../ButtonElement";
import SocialIconsBar from "../SocialIcons";
import Hi from "../../assets/hi-there.gif";
import Scroll from "../../assets/scroll.gif";
import Hero from "../../assets/hero.png";


function HeroSection() {

  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <>
      <HeroContainer id="top" style={{ backgroundImage: `url(${Hero})` , backgroundSize: 'cover'}}>
        <HeroWrapper>
          <Row>
            <Column1>
              <HeroContent>   
                <Gif src={Hi} alt="gif" />
                <HeroH4>
                  Hi there!, I'm
                </HeroH4>
                  <HeroH1>Tek Raj Joshi</HeroH1>
                <HeroP>Web Developer</HeroP>
                <>
                  <SocialIconsBar color="#212221" />
                </>

                <HeroBtnWrapper>
                  <a href={Resume} download style={{textDecoration: 'none'}}>
                    <Button
                      large
                      to="download"
                      onMouseEnter={onHover}
                      onMouseLeave={onHover}
                    >
                      Download Resume
                    </Button>
                  </a>
                </HeroBtnWrapper>
              </HeroContent>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={Svg} alt="" />
              </ImgWrap>
            </Column2>
          </Row>
          <ScrollDown
          to="about"
          activeClass="active"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          <ScrollGif src={Scroll} alt="gif" />
        </ScrollDown>
        </HeroWrapper>
        
      </HeroContainer>
    </>
  );
}

export default HeroSection;
