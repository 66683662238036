import styled from 'styled-components'


export const SocialIcon = styled.a`
    color: ${props => props.props.primary ? "#DDEDF4" : "#212221"};
    margin: 10px;
    
    &:hover {
       color:#1181B2;
       transition: 0.2s ease-in-out; 
    }
`

export const SocialIconsMenu = styled.ul`
    display: inline;
    margin-top: 90px;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2 ease-in-out;
    text-decoration: none;
    cursor: pointer;
`