import React from "react";
import {
  AboutContainer,
  AboutContent,
  Column2,
  Column1,
  Row,
  Heading,
  Subtitle,
  ProfileWrap,
  AboutWrapper,
  ProfileCard
} from "./AboutElements";
import { Icon } from "../Icon";
import AboutIcon from '../../assets/about.svg'
import { useSpring } from 'react-spring'


const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`
function About() {
  const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }))

  return (
    <AboutContainer>
      <div style={{height: "2rem"}}></div>
      <AboutWrapper id="about">
        <Row >
          <Column1 >
              <ProfileWrap onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
                <ProfileCard style={{ transform: props.xy.interpolate(trans1) }} />
            </ProfileWrap>
          </Column1>
          <Column2>
            <AboutContent>
              <Heading><Icon src={AboutIcon} alt="icon" />  More about me</Heading>
              <Subtitle>
                I’m a self taught web developer based in <b>Mahendranagar, Nepal</b> and I am currently pursuing BTECH from Dehradun, India. <br /><br />
                I use code to create things on the internet. I got into development because computers have always fascinated me. <br /><br />
                When I'm not coding you can find me watching web series or movies, surfing instagram. 
                I'm passionate about tech and startups.
                <br />
                <br />
                <b>Wanna say something?</b>
                <br />
                <br />
                Head over to the <b>contact section</b> and send me a text. I would love to hear from you!
              </Subtitle>
            </AboutContent>
          </Column2>
        </Row>
      </AboutWrapper>
    </AboutContainer>
  );
}

export default About;
