import React from 'react'
import SocialIconsBar from '../SocialIcons'
import {FooterContainer, IconWrapper, FinalLine, Love, FooterLine} from './FooterElements'
import { Typography } from '@material-ui/core';

function Footer() {
    return (
        <>
        <FinalLine>Thanks for scrolling, that's all folks.</FinalLine>
        <FooterContainer>
            <IconWrapper>
                <SocialIconsBar primary/>
            </IconWrapper>
            <FooterLine>Made with <Love /> and react.js by Tek Raj Joshi.</FooterLine>
            <Typography varient="body2">Copyright © 2021 | All Rights Reserved</Typography>
        </FooterContainer>
        </>
    )
}

export default Footer
