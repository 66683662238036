import React from 'react';
import Grid from '@material-ui/core/Grid';
import {SkillsContainer, Img, Title, SkillWrapper, SkillData , Heading} from './SkillElements'
import {SkillInfo} from './Data'
import { Icon } from '../Icon';
import SkillIcon from '../../assets/skills.svg'

function Skills() {
  return (
    <SkillsContainer>
      <Heading><Icon src={SkillIcon} alt="icon"/> Skills </Heading>
      <Grid container spacing={2}>
        {SkillInfo.map(({id, logo, title, skills}) =>
          <Grid item xs={12} sm={6} md={4} key={id}>
              <SkillWrapper>
                  <Img src={logo.default} alt={id} />
                      <Title>{title}</Title>
                      <SkillData>({skills})</SkillData>
              </SkillWrapper>
          </Grid>
        )}
      </Grid>
    </SkillsContainer>
  );
}

export default Skills;