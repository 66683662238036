import React from 'react'
import {AiFillInstagram} from 'react-icons/ai'
import {FaLinkedinIn} from 'react-icons/fa'
import {RiFacebookCircleFill} from 'react-icons/ri'
import {FaGithubAlt} from 'react-icons/fa'

import {SocialIconsMenu,
SocialIcon
} from "./SocialElements";

function SocialIconsBar(props) {
    return (
        <>
            <SocialIconsMenu>
            <SocialIcon props={props} href="https://instagram.com/t3kraj">
                    <AiFillInstagram />
                </SocialIcon> 
            <SocialIcon props={props} href="https://www.linkedin.com/in/t3kraj/">
                    <FaLinkedinIn />
                </SocialIcon> 
            <SocialIcon props={props} href="https://facebook.com/t3kraj">
                    <RiFacebookCircleFill />
                </SocialIcon>  
            <SocialIcon props={props} href="https://github.com/t3kraj">
                    <FaGithubAlt />
                </SocialIcon>
            </SocialIconsMenu>
        </>
    )   
}

export default SocialIconsBar
